<template>
  <div id="app">
    <HelloWorld msg="Annie Yeh - Psychologist"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

