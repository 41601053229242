<template>
<div id="cont">
  <div id="hello">
   <!--<v-card id="network"><h3>Dr. Yeh is currently out-of-network</h3></v-card>-->
   <v-card id="topbar">
     <h2 id="title">Annie Yeh, M.D.</h2>
     <p id="about">About</p>
     <p id="services">Services</p>
     <v-btn id="contact">Contact</v-btn>
     <div id="hamburger" @click="showMenu"><i class="material-icons" 
              id="menu1">menu</i></div>
   </v-card>

      <v-card id="menu2">
     <h2 id="title2">Annie Yeh, M.D.</h2>
     <p id="about2">About</p>
     <p id="services2">Services</p>
     <v-btn id="contact2">Contact</v-btn>
   </v-card>

   <!-- <h1 id="intro"><b>Hello! <span id="name">I'm Annie Yeh.</span>
    <br>Liscenced Psychiatrist.</b></h1>
    <img id="profile" src="../assets/profile.png"/>-->
    

  <div class ="container-fluid" style="display: table-cell; vertical-align: middle;">
  <div class ="row">

    <img id="img1" style="width: 100vw; height: 100vh; object-fit: cover; position: absolute; z-index: 1; top: 0;" src="../assets/zen2.jpg"/>
    <v-btn id="explore"><i class="material-icons" style="font-size: 45px; color: #ffb10a;">keyboard_arrow_down</i></v-btn>
    <div class ="col-md-7 col-sm-7">
    </div>
    <div class ="col-md-5 col-sm-5">
      <div>
      <!--<img id="profile" src="../assets/profile.png"/>-->
      </div>
     </div>
  </div>
</div>

  </div>
  <div id="aboutSection">

  <img id="profile2" src="../assets/profile.jpg"/>
  <center><h1 id="intro2"><b><span id="name2">Annie Yeh, <span>M.D.</span></span>
    <br>Integrative Psychiatrist</b><br>    <div id="scrollAbout"></div><span style="font-family: 'Poppins'; font-size: 18px; color: #271651;">New Orleans, LA</span></h1></center>

<div id="">
<div  class="col-xs-1" align="center">

  <div id="aboot">
      <p id="noticeh3">My Approach:</p>
    <p id="aboutp1"><span style="font-size: 30px; font-weight: 600; font-family: Times New Roman;">
      I</span> am a <b>board-certified psychiatrist</b> who believes in a holistic treatment approach to mental 
      health and wellness. As an integrative psychiatrist, I combine healing practices from Eastern traditions 
      with my training in Western medicine in my treatment approach.</p>
  <p id="aboutp2">As a medically trained doctor, I utilize evidence-based psychopharmacology and psychotherapy to 
    treat a variety of mental health issues. <b>I strongly believe in the mind-body connection and augment my 
      practice with evidence-based nutraceutical, yoga, meditation and breathing techniques</b>. My aim is to 
      collaborate with you to help guide you toward achieving your optimal mental, physical, and spiritual 
      health.</p>
    <p id="aboutp2">I have training in psychotherapy, specifically in cognitive behavioral therapy, 
      interpersonal therapy, <b>acceptance and commitment therapy</b>, dialectic behavioral therapy, and psychodynamic 
      psychotherapy which I incorporate as indicated. I take a <b>trauma-informed approach in my work with clients.</b></p>
  </div>
</div>


   <div  class="col-xs-1" align="center">
     <div id="philoNotice" style="position: absolute;">
      <p id="philoh3">My Philosophy:</p>
        <p id="philoBlurb">I am trained in the Western model of mental health but believe strongly in an <b>integrated approach</b> to
     mental health and wellness. The human mind and body are intricately complex and interconnected. 
     Lifestyle factors such as nutrition, exercise, sleep, relationships and habitual patterns can have a 
     lasting impact on our total mind-body wellness. These are core concepts that I explore in depth with
      my clients.
      </p>
     </div>
  </div>
</div>

  <div id="scrollServices"></div>

<div id="servicesBlurb">
<center>
  <div id="servAlert" class="col-sm-9"><i class="material-icons" style="display: table-cell; vertical-align: middle; font-size: 45px; color: #8fe8c2; padding-left: 10px; padding-right: 20px;">announcement</i> <span style="font-weight: 700;">Currently accepting new clients. Due to the COVID-19 pandemic, offering both in-person and telehealth sessions.</span></div>
    </center>
<h1 id="servicesh1">Services:</h1>
   <div  class="col-xs-1" align="center">
    <p id="servicesB">
      I have experience treating depression, anxiety, PTSD, OCD
      and women’s health issues. My training includes working with individuals and with
      families through grief and life transitions.
  </p>
  </div>
<center>
    <table id="tblServ">
       <thead>
        <th style="font-weight: 600;
      text-align: left;
      line-height: 15px;
      padding-left: 0px;
      font-family: 'Pacifico', cursive;
      font-size: 20px;
      color: #271651;
      letter-spacing: 1px;">Specialties</th>
      </thead>
    <tbody>
      <tr>
        <td id="info"><li><span>Depression</span></li></td>
        <td id="info"><li><span>Anxiety</span></li></td>
        <td id="info"><li><span>PTSD</span></li></td>
        <td id="info"><li><span>Grief</span></li></td>
 
      </tr>

      <tr>
        <td id="info"><li><span>OCD</span></li></td>
        <td id="info"><li><span>Womens Health</span></li></td>
            <td id="info"><li><span>Life Transitions</span></li></td>
      </tr>
      </tbody>
  </table>
</center>

    <table id="tblServ2">
      <thead>
        <th style="font-weight: 600;
      text-align: left;
      padding-left: 0px;
      font-family: 'Pacifico', cursive;
      font-size: 25px;
      color: #271651;
      letter-spacing: 1px;">Specialties</th>
      </thead>
    <tbody>
    <tr><td id="info"><li><span>Depression</span></li></td></tr>
       <tr> <td id="info"><li><span>Anxiety</span></li></td></tr>
       <tr> <td id="info"><li><span>PTSD</span></li></td></tr>
       <tr> <td id="info"><li><span>Grief</span></li></td></tr>
      <tr><td id="info"><li><span>OCD</span></li></td></tr>
       <tr> <td id="info"><li><span>Addiction</span></li></td></tr>
       <tr> <td id="info"><li><span>Womens Health</span></li></td></tr>
       <tr> <td id="info"><li><span>Life Transitions</span></li></td></tr>
      </tbody>
  </table>

  </div>




  <div id="servicesSection">
    <p id="psytoday" style="display: none;">Psychology Today<a href="https://www.psychologytoday.com/profile/828505"></a></p>
  </div>
      <div id="proSection">
        <div class="jumbotron" id="jumboTwo">
        <section class="features-icons bg-#e9ecef text-center">
          <div class="container">
            <div class="row">
              <div class="col-lg-4">
                 <div class="col-lg-10" style="height: 180px; margin: 0 auto; border-radius: 14px; background: #fff5e0; box-shadow:  5px 5px 5px #bfb8a8, -5px -5px 5px #ffffff;">
                <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                  <div class="features-icons-icon d-flex">
                   <center><i class="material-icons" style="font-size: 45px; color: #ef5074;">all_inclusive</i></center>
                  </div>
                  <h3 style="margin-top: 20px;">Holistic Approach</h3>
                  <p
                    class="lead mb-0"
                    style="
                      font-size: 15px;
                       font-family: 'Open Sans', sans-serif;
                       text-align: center;
                    "
                  >
                  Whole-body mental wellness including Psychopharmceutical, nutraceutical and mind-body interventions. 
                  </p>
                </div>
              </div>
              </div>

              <div class="col-lg-4">
                <div class="col-lg-10" style="margin: 0 auto; height: 180px; border-radius: 14px; background: #fff5e0; box-shadow:  5px 5px 5px #bfb8a8, -5px -5px 5px #ffffff;">
                <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                  <div class="features-icons-icon d-flex">
                <center><i class="material-icons" style="font-size: 45px; color: #ffb10a;">self_improvement</i></center>
                  </div>
                  <h3 style="margin-top: 20px;">Compassionate Care</h3>
                  <p
                    class="lead mb-0"
                    style="
                      font-size: 15px;
                       font-family: 'Open Sans', sans-serif;
                       text-align: center;
                    "
                  >
                    Create a safe, nurturing and affirming environment for all patients. 
                  </p>
                </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="col-lg-10" style="height: 180px; margin: 0 auto; border-radius: 14px; background: #fff5e0; box-shadow:  5px 5px 5px #bfb8a8, -5px -5px 5px #ffffff;">
                <div class="features-icons-item mx-auto mb-0 mb-lg-3">
                  <div class="features-icons-icon d-flex">
                  <center><i class="material-icons" style="font-size: 45px; color: #835be3;">question_answer</i></center>
                  </div>
                  <h3 style="margin-top: 20px;">Initial Consultation</h3>
                  <p
                    class="lead mb-0"
                    style="
                      font-size: 15px;
                       font-family: 'Open Sans', sans-serif;
                       text-align: center;
                    "
                  >
                    A 2-hour first meeting helps us plan your wellness journey in-depth.
                  </p>
                </div>
                </div>
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>


<div class="container">
  <div class="row">
    <div class="col-md-6" style="position: relative; height: 220px; margin-top: 40px;">
      <br>
        <h3 id="eduh3">Education</h3>
          <hr style="margin-left: 70px; margin-right: 70px;">
    <div>
      <ul id="eduList"  style="margin-top: 85px; width: 500px; position:absolute; left:50%; top:50%; transform: translate(-50%,-50%);">
      <li><b>Bachelor of Arts</b> - University of California, Berkeley</li>
      <li><b>Postbaccalaureate Premedical Program</b> - Bryn Mawr College</li>
      <li><b>Medical School</b> - LSU Health Sciences Center - New Orleans</li>
      <li><b>Residency</b> - Tulane University</li>

        <h3 id="addh3">Additional Training</h3>
               <hr style="margin-left: 35px; margin-right: 35px;">
          <ul style="list-style-type: none !important; padding-top: 15px;">
          <li>Certified 200-hour Yoga Teacher Training</li>
          <li>Trauma Center-Trauma Sensitive Yoga Training</li>
          </ul>
      
      </ul>
      </div>
    </div>

    <div class="col-md-6" style="position: relative; height: 320px; margin-top: 58px;">
          <h3 id="awardh3">Honors & Awards</h3><hr style="margin-left: 70px; margin-right: 70px;">
      <ul id="awardList">
        <li style="padding-top: 10px;"><b>Excellence in Psychiatry Award</b> presented by the Louisiana Psychiatric Medical Association Award for graduating Resident-Fellow 
            representing outstanding achievement and promise in the field of psychiatry</li>

      <li style="padding-top: 10px;">The <b>Dr. Robert Lancaster Prize in Psychiatry</b> for the most caring psychiatrist among my graduating residency class, Tulane University School of Medicine</li>
      <li style="padding-top: 10px;"><b>Leadership & Education Program for Students in Integrative Medicine</b>, the Academic Consortium for Integrative Medicine and Health, 2015</li>
      </ul>


    </div>


  </div>
</div>
</div>

  <div id="contactSection">
      <div id="contactScroll"></div>
  <div  class="col-xs-1" align="center">
    <div id="notice">
      <center>
  <div id="contAlert" class="col-sm-11"><i class="material-icons" id="icoCont" style="display: table-cell; vertical-align: middle; font-size: 45px; color: #835be3; padding-left: 10px; padding-right: 20px;">announcement</i>
  <span style="font-weight: 700;">Due to the small and intimate nature of my practice with minimal staff and a focus on privacy, 
  I am not currently able to process insurance claims through my office. I am able to provide a 
  detailed receipt at the end of each appointment for you to submit directly to your insurance company 
  for personal reimbursement. <a href="tel:504-383-5362"> Please feel free to call the office for more information</a> on our fee schedule 
  and to set up an appointment with Dr. Yeh.</span></div>
    </center>
      <p id="noticeh3">Contact Me</p>

        <form onsubmit="return false">

  <div class="form-group">
    <input required type="text" class="form-control col-sm-5" id="name1" placeholder="Name" v-model="theName">
  </div>
  <div class="form-group">
    <input required type="email" class="form-control col-sm-5" id="email1" placeholder="Email Address" v-model="theEmail">
  </div>
   <div class="form-group">
    <input required type="phone" class="form-control col-sm-5" id="email1" placeholder="Phone Number" v-model="thePhone">
  </div>
    <div class="form-group">
    <textarea required type="textarea" rows="5" class="form-control col-sm-5" id="message1" placeholder="Message" v-model="theMessage"></textarea>
  </div>
  <v-btn id="btnEmail" style=" margin-bottom: 30px; font-family: 'Poppins'; color: white; background-color: #1f74ff; border-radius: 50px; height: 50px; margin-top: 5px;"
   class="col-sm-5" @click="sendEmail()">Send Message</v-btn>
</form>

 


        <center>
      <table id="tbl1">
        <tbody>
          <tr id="call1">
        <td><i class="material-icons" id="ico1" style="font-size: 35px; color: #ef5074;">call</i><h2 class="subtitle2" id="info2"> Phone:</h2><td>
        <td><a href="tel:504-383-5362" class="phone"> 504-383-5362 </a></td></tr>
       <br>
        <tr id="email1"><td><i class="material-icons" id="ico2" style="font-size: 35px; color: #835be3;">email</i><h2 class="subtitle2" id="info2"> Email:</h2><td>
        <td><a href="mailto:annieyehmd@gmail.com" class="phone"> annieyehmd@gmail.com </a></td></tr>
        <br>
         <!--  <tr id="loc1"><td><i class="material-icons" id="ico3" style="font-size: 35px; color: #ffb10a;">location_on</i><h2 class="subtitle2" id="info2"> Location:</h2><td>
        <td class="phone2">619 Pink Street, Metairie, LA 70005</td></tr>-->
        </tbody>

      </table>

     <!-- <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=619%20Pink%20Street%20Metairie,%20LA+(Dr%20Annie%20Yeh's%20Office%20Address)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <a href='https://www.add-map.net/'></a></div>
</div>-->
      </center>
  </div>
  </div>

  <div class ="container-fluid" style="display: table-cell; vertical-align: middle;">
  <div class ="row" style="width: 100vw; margin-top: 20px;">

    <div class ="col-md-12 col-sm-12">
      <center>
      <table id="tbl1">
      
      </table>

     <!-- <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=619%20Pink%20Street%20Metairie,%20LA+(Dr%20Annie%20Yeh's%20Office%20Address)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <a href='https://www.add-map.net/'></a></div>
</div>-->
      </center>


    </div>

   <!-- <div class ="col-md-6 col-sm-6" style="border: 1px solid red;">
  
    </div>-->
</div>

  </div>




  </div>

  <div id="footer">
    <center>
    <table id="tblfoot">
    <tbody>
      <tr id="footRow">
    <td id="telfoot"><a href="tel:504-383-5362" class="telink"><i class="material-icons-outlined" id="icoPho" style="font-size: 28px;">phone_in_talk</i></a></td>
    <td id="emfoot"><a href="mailto:annieyehmd@gmail.com" class="emlink"><i class="material-icons-outlined" id="icoEm" style="font-size: 28px;">email</i></a></td>
    </tr>

    </tbody>
    </table>
    </center>
  </div>


</div>



</template>

<script>
import $ from 'jquery';
import emailjs from 'emailjs-com'
export default {
  data() {

return {
     theName: "",
     theEmail: "",
     theMessage: "",
     thePhone: "",
     conCat: [],
    chip3: true,
  }
},
  mounted() {

    emailjs.init('user_hc6uP7lNg7ihD2LaTlyl7');

    $("#services").click(function() {
    $('html,body').animate({
        scrollTop: $("#scrollServices").offset().top},
        'slow');
});

    $("#about").click(function() {
    $('html,body').animate({
        scrollTop: $("#scrollAbout").offset().top},
        'slow');
    });

        $("#explore").click(function() {
    $('html,body').animate({
        scrollTop: $("#scrollAbout").offset().top},
        'slow');
    });

    $("#contact").click(function() {
    $('html,body').animate({
        scrollTop: $("#contactScroll").offset().top},
        'slow');
    });

      $("#services2").click(function() {
    $('html,body').animate({
        scrollTop: $("#scrollServices").offset().top},
        'slow');
      $('#menu2').hide();
});

    $("#about2").click(function() {
    $('html,body').animate({
        scrollTop: $("#scrollAbout").offset().top},
        'slow');
        $('#menu2').hide();
    });

        $("#explore2").click(function() {
    $('html,body').animate({
        scrollTop: $("#scrollAbout").offset().top},
        'slow');
      $('#menu2').hide();
    });

    $("#contact2").click(function() {
    $('html,body').animate({
        scrollTop: $("#contactScroll").offset().top},
        'slow');
            $('#menu2').hide();
    });

  },
  methods: {
    showMenu() {
      document.getElementById('menu2').style.display = "block"
    },
    sendEmail() {
    
     this.conCat = "Client Name: " + this.theName + " - Client Email: " + this.theEmail + " - Client Phone: " + this.thePhone + " - Client Message: " + this.theMessage;
     console.log(this.conCat)
     var data1 = this.conCat

      // Email.js API, the above variable 'data1' contains the answer data values for all inputs
      var template_params = {
        subject: data1
      };
      var service_id = "default_service";
      var template_id = "annietemp";
      emailjs.send(service_id, template_id, template_params);
      window.alert("Message sent to annieyehmd@gmail.com");
       $('#btnEmail').attr("disabled","disabled");
       $('#btnEmail').css("opacity", "0.5");
    }
  }
  
}
</script>


<style scoped>

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

#hello {
  width: 100vw;
	height: 100vh;
  background-color: #fff5e0;
  display: table;
}

#network {
 width: 100%;
  height: 30px;
  top: 0;
  position: fixed;
  z-index: 10000 !important;
  border-radius: 0px;
  background-color: 10px;

}


#topbar {
  width: 100%;
  height: 70px;
  /*margin-top: 30px;*/
  top: 0;
  position: fixed;
  z-index: 10000 !important;
  border-radius: 0px;
}

#title {
  position: absolute;
  left: 0;
  margin-left: 20px;
  line-height: 70px;
    font-family: 'Pacifico', cursive !important;
  background-color: white;
  color:#271651;
}

#home {
  position: absolute;
  right: 0;
  margin-top: 22px;
  margin-right: 405px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  box-shadow: none;
  border-radius: 25px;
  border: none;
  color: black;
  letter-spacing: 0.5px;
  text-transform: none;
  cursor: pointer;
}


#home:hover {
  transition: 0.2s ease-in-out;
  color: #835be3;
}

#about {
  position: absolute;
  right: 0;
  margin-top: 22px;
  margin-right: 305px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  box-shadow: none;
  border-radius: 25px;
  border: none;
  color: black;
  letter-spacing: 0.5px;
  text-transform: none;
  cursor: pointer;
}

#about:hover {
  transition: 0.2s ease-in-out;
  color: #835be3;
}


#services {
  position: absolute;
  right: 0;
  margin-top: 22px;
  margin-right: 195px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  box-shadow: none;
  border-radius: 25px;
  border: none;
  color: black;
  letter-spacing: 0.5px;
  text-transform: none;
  cursor: pointer;
}

#services:hover {
  transition: 0.2s ease-in-out;
  color: #835be3;
}



#contact {
  position: absolute;
  right: 0;
  margin-top: 12px;
  height: 45px;
  width: 130px;
  margin-right: 20px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  box-shadow: none;
  border-radius: 25px;
  border: none;
  background-color: #835be3;
  color: white;
  letter-spacing: 0.5px;
  text-transform: none;
}

#intro2 {
  font-family: 'Poppins', sans-serif;
  font-size: 25.83px;
  line-height: 40.037499999999994px;
  font-weight: 1200;
  text-transform: capitalize;
  z-index: 1000 !important;
  font-family: 'Pacifico', cursive;
  margin-top: -20px;
      color: #ef5074 !important;
  }

  #name2 {
    color: #271651;
    font-family: 'Pacifico', cursive;
  }

  #profile2 {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 15px;
    object-fit: cover;
    z-index: 1000 !important;
    margin-top: -250px;
    border: 10px solid #ffe6b0;
    left: 50%;
    margin-left: -100px;
  }

  
#explore {
  bottom: 0;
  margin-bottom: 100px;
  height: 55px;
  width: 100px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  box-shadow: none;
  border-radius: 50px;
  border: none;
  background-color: rgb(0,0,0,0.8);
  letter-spacing: 0.5px;
  text-transform: none;
  position: absolute;
  z-index: 3000;
  left: 50%;
  margin-left: -50px;
}



#abouth1 {
  text-align: center;
  width: 280px;
  height: 40px;
    font-family: 'Poppins', sans-serif;
    color: #271651;
   border-left: 10px solid #835be3;
    border-right: 10px solid #835be3;
   line-height: 45px;
}

#aboutSection {
  height: 1280px;
  width: 100vw;
    background-color: #fff5e0;
    padding-top: 300px;
}

#proSection {
  padding-top: 50px;
}

#p1 {
 font-family: 'Open Sans', sans-serif;
  width: 800px;
  margin-top: 30px;
  font-size: 17px;
  line-height: 25px;
  text-align: left;
  text-indent: 50px;
}


#p2 {
 font-family: 'Open Sans', sans-serif;
  width: 800px;
  margin-top: 15px;
  font-size: 17px;
  line-height: 25px;
  text-align: left;
  text-indent: 50px;
}

h3 {

   font-family: 'Open Sans', sans-serif !important;
   text-align: center;
   padding-bottom: 5px;


}

#scrollAbout {
  height: 1px;
  width: 100%;
  display: hidden;
}

#servicesh1 {
  text-align: left;
  width: 280px;
  height: 40px;
       font-family: 'Pacifico', cursive;
    color: #271651;
   line-height: 75px;
   letter-spacing: 1px;
   margin-left: 40px;
         font-size: 28px;
}

#servicesScroll {

  

}

#servicesSection {
  height: 350px;
  width: 800px;
  margin-top: 0px;
  background-color: #fff5e0;

}

#servAlert {
  background-color: #edfbf6;
    display: table;
    font-family: 'Open Sans', sans-serif;
  color: #271651;
  font-size: 14px;
  font-weight: 100;
  margin-top: 10px;
  border-radius: 50px;
  border: 5px solid #8fe8c2;
  line-height: 25px;
}

#servicesBlurb {
    background-color: #ffecc4 !important;
  border-left: 15px solid #fcd98f;
  border-radius: 15px;
  width: 850px;
  height: 420px;
  margin-top: 330px;
        position: absolute;
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto; 
}

table {
  margin-top: 30px;
}

#info {
  padding-right: 50px;
  padding-top: 20px;
  font-family: 'Poppins', sans-serif;
  color: #271651;
  text-align: left;
}


li span {
  position: relative;
  left: -7px;
}

#contactSection {
  padding-top: 960px;
  height: 1970px;
  width: 100vw;
  background-color:#fff5e0;


}

#contacth1 {
    text-align: center;
  width: 200px;
  height: 40px;
    font-family: 'Poppins', sans-serif;
    color: #271651;
   border-left: 10px solid #ef5074;
    border-right: 10px solid #ef5074;
   line-height: 45px;
}

#info2 {
  padding-right: 20px;
  font-family: 'Poppins', sans-serif;
  color: #271651;
  text-align: left;
  line-height: 15px;

}

.phone {
    padding-right: 20px;
  font-family: 'Poppins', sans-serif;
  color: #271651;
  text-align: left;
  text-decoration: none;
  font-size: 20px;

}


.phone2 {
    padding-right: 20px;
  font-family: 'Poppins', sans-serif;
  color: #271651;
  text-align: left;
  text-decoration: none;
  font-size: 20px;

}

.phone:hover {
    color: #6f44db;
    transition: 0.2s ease-in-out;
}

#ico1 {

  position: absolute;
  margin-left: -50px;
  margin-top: -10px;
 
}

#ico2 {
    position: absolute;
  margin-left: -50px;
  margin-top: -10px;

}

#ico3 {
    position: absolute;
  margin-left: -50px;
  margin-top: -10px;

}


#tbl1 {
  margin-left: 70px;
}

.mapouter{
  text-align:right;
  height:400px;
  width: 100%;
  margin-left: 20px;
  }
  
  .gmap_canvas {
    overflow:hidden;
    background:none!important;
    margin-top: 30px;
    height:400px;
    width:100%;}


    #hamburger {
      display: none;
    }

    #menu2 {
      display: none;
    }

    #eduList {
      font-family: 'Montserrat', sans-serif;
      text-align: left;
      list-style-type: none;
      line-height: 28px;
      margin-top: 10px;
    }

    #awardList {

    font-family: 'Montserrat', sans-serif;
      text-align: left;
      line-height: 28px;
      margin-top: 10px;

    }

    #footer {
      display: block;
      bottom: 0;
      position: fixed;
      width: 100%;
      height: 50px;
      background-color: #271651;
      z-index: 1000;
    }

    .telink {
      text-decoration: none;
      color: #ffb10a !important; font-family: 'Poppins', sans-serif; 
    font-size: 18px; cursor: pointer;
    padding-right: 30px;
    }

        .emlink {
      text-decoration: none;
      color: #ffb10a !important; font-family: 'Poppins', sans-serif; 
    font-size: 18px; cursor: pointer;
    }

    #tblfoot {
     margin-top: 10px;
    }

    #noticeh3 {
      margin-bottom: -20px;
      font-weight: 600;
      text-align: left;
      padding-left: 25px;
      font-family: 'Pacifico', cursive;
      font-size: 28px;
      color: #271651;
      letter-spacing: 1px;
    }

    
#pnotice {
 font-family: 'Open Sans', sans-serif;
  width: 800px;
  margin-top: 30px;
  font-size: 17px;
  line-height: 25px;
  text-align: left;
  text-indent: 50px;
  padding-left: 25px;
    padding-right: 25px;
}

    #notice {

      background-color:#edfbf6;
      width: 850px;
      border-radius: 15px;
      border-left: 15px solid #8fe8c2;
      padding: 20px;
      margin-top: 30px;
    }

    #philoNotice {
      background-color: #eeecff;
      border-left: 15px solid #b3abff;
      padding: 20px;
      width: 850px;
      height: 225px !important;
      border-radius: 15px;
      z-index: 1000;
      margin-top: 55px;
      position: absolute;
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto; 
    }

    #philoh3 {
      margin-bottom: -20px;
      font-weight: 600;
      text-align: left;
      padding-left: 25px;
      font-family: 'Pacifico', cursive;
      font-size: 25px;
      color: #271651;
          letter-spacing: 1px;
    }


#philoBlurb {
  font-family: 'Open Sans', sans-serif;
  width: 800px;
  height: 220px;
  margin-top: 35px;
  font-size: 17px;
  line-height: 25px;
  text-align: left;
  text-indent: 50px;
  padding-left: 25px;
    padding-right: 25px;
}

#contactScroll {
 
}

#contAlert {
  background-color: #eeecff;
  display: table;
   font-family: 'Open Sans', sans-serif;
  color: #271651;
  font-size: 15px;
  font-weight: 100;
  margin-top: 10px;
  border-radius: 50px;
  border: 3px solid #b3abff;
  margin-bottom: 20px;
  line-height: 25px;
}

#proSection {
margin-top: 370px;
margin-bottom: -20px;
  
}

#aboot {
      background-color: #ffdbdb;
      border-left: 15px solid  #ffa8a8;
      padding: 20px;
      width: 850px;
      height: 465px !important;
      border-radius: 15px;
      z-index: 1000;
      margin-top: 10px;
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto; 
}

#tblServ {

  margin-top: 40px;

}


#tblServ2 {
display: none;

}

  #aboutp1 {
 font-family: 'Open Sans', sans-serif;
  width: 790px;
  margin-top: 30px;
  font-size: 17px;
  line-height: 25px;
  text-align: left;
  text-indent: 50px;
}
  #aboutp2 {
 font-family: 'Open Sans', sans-serif;
  width: 790px;
  margin-top: 30px;
  font-size: 17px;
  line-height: 25px;
  text-align: left;
  text-indent: 50px;
}

  #awardh3 {

    font-family: 'Pacifico', cursive !important;
    font-size: 22px;
    color: #271651;
  }

    #eduh3 {

    font-family: 'Pacifico', cursive !important;
    font-size: 22px;
        color: #271651;
  }

      #addh3 {

    font-family: 'Pacifico', cursive !important;
    font-size: 22px;
        color: #271651;
  }

  #servicesB {

     font-family: 'Open Sans', sans-serif;
  width: 800px;
  margin-top: 30px;
  font-size: 17px;
  line-height: 25px;
  text-align: left;
  text-indent: 50px;

  }

  #name1 {
    border: 3px solid #271651;
    border-radius: 40px;
    outline: none;
    padding: 15px;
    margin-top: 30px;
       font-family: 'Poppins';
    font-size: 14px;
  }

  #name1:focus {
    background-color:#8fe8c2;
    transition: ease-in 0.2s;
}

  #email1 {
    border: 3px solid #271651;
    border-radius: 40px;
    outline: none;
    padding: 15px;
    margin-top: 5px;
       font-family: 'Poppins';
    font-size: 14px;
  }

    #email1:focus {
    background-color:#8fe8c2;
    transition: ease-in 0.2s;
}

  
  #message1 {
    border: 3px solid #271651;
    border-radius: 15px;
    outline: none;
    padding: 15px;
    margin-top: 5px;
    font-family: 'Poppins';
    font-size: 14px;
  }

  #message1:focus {
    background-color:#8fe8c2;
    transition: ease-in 0.2s;
}

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
   font-family: 'Poppins';
    font-size: 14px;
        color: #271651;
}



    /*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@


                                        MOBILE STYLES


    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/

    @media only screen and (max-width: 1000px) {

      #hello {
        display: block;
      }

      #img1 {

       margin-left: 12px;
      }

  #p1, #p2 {
    width: 340px;
  }

  #aboutp1 {
 font-family: 'Open Sans', sans-serif;
  width: 290px;
  margin-top: 30px;
  font-size: 17px;
  line-height: 25px;
  text-align: left;
  text-indent: 50px;
}
  #aboutp2 {
 font-family: 'Open Sans', sans-serif;
  width: 290px;
  margin-top: 30px;
  font-size: 17px;
  line-height: 25px;
  text-align: left;
  text-indent: 50px;
}


  #profile {
    width: 180px;
    height: 180px;
    top: 0;
    margin-top: 170px;
    position: absolute;
  left: 50%;
  margin-left: -90px;
 
  }

  #intro {
    width: 90%;
    margin-left: 20px;
    font-size: 29px;
    margin-top: 380px;
    letter-spacing: -1px;
    line-height: 35px;

  }

  #title {

  }

    #contact {
    display: none;
  }

    #services {
    display: none;
  }

    #about {
    display: none;
  }


  #proSection {
    padding: 20px;
    margin-top: -1640px !important;

  }



  .col-xs-1 {
    background-color: #fff5e0;
  }

  #eduList {
    width: 90%!important;
  }
    #awardh3 {

    font-family: 'Pacifico', cursive !important;
    font-size: 22px;
    margin-top: 80px;
    
  }

    #eduh3 {

    font-family: 'Pacifico', cursive !important;
    font-size: 22px;
    margin-top: -50px;
  }

      #addh3 {

    font-family: 'Pacifico', cursive !important;
    font-size: 22px;
    margin-top: 30px;
  }

  #awardsList {

 
  }

  #abouth1 {
    margin-bottom: 25px;

  }

  #aboutSection {
  height: 710px;
  }

    #servicesSection {
    height: 3050px;
    padding-top: 30px;
    margin-top: 0px;
    width: 100%;
  }

  #contactSection {
    height: 700px;
    margin-top: 2680px;
    padding-top: 625px;
    padding-bottom: 1200px !important;
    background-color: #fff5e0;
  }

    #icoCont {
      width: 20px !important;
      height: 20px !important;
  }

  #servicesBlurb {
    width: 320px;
    height: 880px;
    margin-top: 535px;
  }




#tbl1 {
}



h1 {

}

#tblServ {
display: none;

}

#tblServ2 {
display: block;
margin-left: 20px;
padding-bottom: 30px;
margin-top: -30px;

}

.subtitle2 {
  font-size: 18px !important;
  margin-left: 5px;
  
}



.phone {
  font-size: 15px !important;
  margin-left: -20px !important;

}

#info2 {
  margin-left: -45px;
  font-size: 15px;
}

.phone2 {
  font-size: 18px !important;
  font-size: 15px;


}

#ico1 {
  margin-top: -20px;
  margin-left: -85px;
  position: absolute;


}

#ico2 {
     margin-top: -10px;
  margin-left: -85px;
  position: absolute;
}

#ico3 {
    margin-top: -20px;
}



#hamburger {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #ef5074;
  margin-top: 10px;
  position: absolute;
  right: 0;
  margin-right: 10px;
}


#hamburger:visited {

  background-color: #cf2950;
}


#menu1 {

  font-size: 40px; color: #271651; margin-left: 5px; margin-top: 5px;
}


#menu2 {
      display: none;
      height: 260px;
      width: 100%;
      z-index: 2000;
      position: fixed;

}

#about2 {

  width: 100%;
  text-align: center;
  margin-top: 70px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  box-shadow: none;
  border-radius: 25px;
  border: none;
  color: black;
  letter-spacing: 0.5px;
  text-transform: uppercase;

}

#services2 {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  box-shadow: none;
  border-radius: 25px;
  border: none;
  color: black;
  letter-spacing: 0.5px;
  text-transform: uppercase;

}


#contact2 {
  margin-top: 20px;
  position: absolute;
  height: 45px;
  width: 130px;
  margin-right: 20px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  box-shadow: none;
  border-radius: 25px;
  border: none;
  background-color: #835be3;
  color: white;
  left: 50%;
  margin-left: -70px;
  letter-spacing: 0.5px;
  text-transform: none;
}

.mapouter{
    margin-left: 10px;
    
  }

  #footer {

    height: 50px;
  }

      .telink {
      text-decoration: none;
      color: #ffb10a !important; font-family: 'Poppins', sans-serif; 
    font-size: 13px; cursor: pointer;
    padding-right: 30px;
    }

        .emlink {
      text-decoration: none;
      color: #ffb10a !important; font-family: 'Poppins', sans-serif; 
    font-size: 13px; cursor: pointer;
    }

    #tblfoot {
     margin-top: 9px;
    }

    
    #noticeh3 {
      font-weight: 600;
      text-align: left;
      padding-left: 0px;
      font-family: 'Pacifico', cursive;
      font-size: 30px;
      color: #271651;
      letter-spacing: 1px;

    }

    
#pnotice {
 font-family: 'Open Sans', sans-serif;
  width: 330px;
  margin-top: 30px;
  font-size: 17px;
  line-height: 25px;
  text-align: left;
  text-indent: 50px;
  padding-left: 25px;
    padding-right: 25px;

}

    #notice {

      background-color:#edfbf6;
      width: 360px;
      border-radius: 15px;
      border-left: 15px solid #8fe8c2;
    
    }

    #philoNotice {
      background-color: #eeecff;
      border-left: 15px solid #b3abff;
      padding: 20px;
      width: 330px;
      height: 485px !important;
      border-radius: 15px;
      z-index: 1000;
      margin-top: 25px;
      position: absolute;
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto; 
    }

    #philoh3 {
    
      font-weight: 600;
      text-align: center;
      margin-left: -30px;
      font-family: 'Pacifico', cursive;
      font-size: 25px;
      color: #271651;
          letter-spacing: 1px;

    }


#philoBlurb {
  font-family: 'Open Sans', sans-serif;
  width: 300px;
  height: 220px;
  margin-top: 35px;
  font-size: 17px;
  line-height: 25px;
  text-align: left;
  text-indent: 50px;
  
}

#contactScroll {
 
}






#aboot {
      background-color: #ffdbdb;
      border-left: 15px solid  #ffa8a8;
      padding: 20px;
      width: 330px;
      height: 690px !important;
      border-radius: 15px;
      z-index: 1000;
      margin-top: 55px;
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto; 

}

#servDiv {

}


  #servicesB {
  font-family: 'Open Sans', sans-serif;
  width: 305px;
  height: 220px;
  margin-top: 35px;
  font-size: 17px;
  line-height: 25px;
  text-align: left;
  text-indent: 50px;
  background-color: #ffecc4;
  padding: 10px;

  }



  
}













</style>